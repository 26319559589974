footer{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 600;
    padding: 15px;
    background-color: rgb(30, 30, 30);
    color: white;
}
a{
    text-decoration: none;
}
footer p{
    text-decoration: none;
    text-align: center;
}