.navf{
    background-color: rgb(30, 30, 30);
}

.nav-link{
    color: white;
}

.nav-link:hover{
    background-color: white;
    color: rgb(30, 30, 30);
    padding: 10px;
}