/* Error.css */

.error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('#') no-repeat center center fixed;
    background-size: cover;
    color: #1d1b1b;
    text-align: center;
  }
  
  .error-title {
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 10rem;
    margin: 0;
  }
  
  .error-message {
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 2rem;
    margin: 20px 0;
  }
  
  .home-link {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .home-link:hover {
    background-color: #0056b3;
  }
  